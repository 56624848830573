import React, { Component } from 'react';
import News from './News/News';

import './Blog.css'

export default class BlogMov extends Component {
   render() {
      return (
         <React.Fragment>
            <div className="BlogMainMovBox">
               <p className="PlayFair BlogTitle" >
                  El hogar de nuestras
               </p>
               <p className="Akzidenz-Roman">
                  <span className="firstBlogText">
                     ÚLTIMAS
                  </span>
                  <span className="secondBlogText" >
                     IDEAS
                  </span>
               </p>
               <div className="newsBox">
                  {/* <News /> */}
                  <iframe
                  title="Blog"
                  width="300"
                  height="576px"
                  src="https://franca.agency/blog/">
               </iframe>
               </div>
            </div>
         </React.Fragment>
      );
   }
}