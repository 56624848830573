import React, { Component } from 'react';
import './AboutUs.css';

export default class AboutUs extends Component {

   render() {
      return (
         <React.Fragment>
            <div className="MainAboutWebBox" id="AboutUS"
               style={{
                  background: "url(/assets/img/Background/Aboutus01.png)",
                  backgroundSize: "cover",
               }}>

               <div className="FullText" id="FullText">
                  <p>
                     <span className="firstText Akzidenz-Roman">CREATING </span> <span className="secondText Freight-Light"> the future</span>
                  </p>
                  <p className="AboutText Freight-Light" id="AboutText">
                     <span className="FirstWord" id="FirstWord">Somos un aliado creativo y estratégico</span>
                     <span> <span id="SecondWord">  en el mundo del marketing digital</span>
                        <span id="LastWord"> que conecta a las personas con las marcas alrededor del mundo. </span></span>
                  </p>
               </div>
            </div>
            {/*MOVIL*/}
            <div className="MainAboutMovBox" id="AboutUS" >
               <div className="FullText" id="FullText">
                  <p>
                     <span className="firstText firstAboutText Akzidenz-Roman">CREATING </span>
                     <span className="secondText Freight-Light"> the future</span>
                  </p>
                  <p className="AboutMovText Freight-Light" id="AboutMovText">
                     <span className="FirstWord" id="FirstMovWord">Somos un aliado creativo y estratégico</span>
                     <span> <span id="SecondMovWord">  en el mundo del marketing digital</span>
                        <span id="LastMovWord"> que conecta a las personas con las marcas alrededor del mundo. </span></span>
                  </p>
               </div>
            </div>
         </React.Fragment>
      );
   }
}