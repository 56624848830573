import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Col, Button, Row, CustomInput } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './TrabajeNosotros.css';

export default class JoinForm extends Component {
    handleSubmit(e) {
        e.preventDefault();
        axios.post('/correo/Join/', {
            name: e.target.name.value,
            email: e.target.email.value,
            position: e.target.select.value,
            cel: e.target.cel.value,
            file: e.target.customFile.value,
            message: e.target.Message.value,
            agree: e.target.Agree.value,
        }).then((response) => {
            debugger;
            if (response.data.status === 'success') {
                alert("Message Sent.");
                e.target.name.value = " ";
                e.target.email.value = " ";
                e.target.cel.value = " ";
                e.target.Message.value = " ";
                e.target.customFile.value = " ";
                e.target.Agree.value = "";
            } else if (response.data.status === 'fail') {
                alert("Message failed to send.")
                console.log("Imprimiendo error", response.data.status);
            }
        })
    }
    render() {
        let closeModalJoin = this.props.closeModalJoin;
        return (
            <React.Fragment>
                <div className="JoinFormBoxPage">
                    <div className="row justify-content-center ">
                        <div className="FormBox col-7">
                            <div className="row">
                                <div className="col-3">
                                    <img src="/assets/img/Forms/JoinForm.png" alt="logo" />
                                </div>
                                <div className="col-9 ">
                                    <Form className="row" onSubmit={(e) => this.handleSubmit(e)}>
                                        <Label className="JoinsTitle JoinTitle">
                                            HAZ PARTE DE ESTE GRAN EQUIPO Y CONTEMOS
                                        </Label>
                                        <Label className="JoinsSubTitle Akzidenz-Roman">
                                            HISTORIAS JUNTOS
                                        </Label>
                                        <Col md={9}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md={4}>
                                                        <Input type="name" name="name" id="name" placeholder="Nombre" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }} />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Input type="email" name="email" id="email" placeholder="E-mail" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }} />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Input type="name" name="cel" id="cel" placeholder="Celular" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <CustomInput type="select" name="select" id="select" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }}>
                                                            <option value="">Cargo</option>
                                                            <option>Ejecutiva/o de cuentas</option>
                                                            <option>Director de Arte</option>
                                                            <option>Director Digital</option>
                                                            <option>Diseñador Gráfico</option>
                                                            <option>Copy Creativo</option>
                                                            <option>Estratega Digital / Community</option>
                                                            <option>Desarrollador</option>
                                                            <option>Animador</option>
                                                            <option>Ejecutivo de Pauta</option>
                                                            <option>Analista Digital</option>
                                                        </CustomInput>
                                                    </Col>
                                                    <Col md={6}>
                                                        <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" label="Adjunta tu HV" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }} />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col md={9}>
                                            <FormGroup>
                                                <Input type="textarea" name="Message" id="Message" rows="4" placeholder="Cuéntanos de ti, tu perfil profesional, experiencia laboral y aspiración salarial" style={{ backgroundColor: "#1D1D1B", color: "white", margin: "5px" }} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12} style={{ textAling: "left" }}>
                                            <FormGroup>
                                                <Input className="form2" type="checkbox" name="Agree" value="Autorizo a la Política de Tratamiento de Datos Personales de FRANCA AGENCY" required />
                                                <Link to={{ pathname: "/tyc", }} target="_blank" style={{ color: "#FFFFFF" }}>
                                                    <span className="Terminos_condiciones">AUTORIZO A LA POLITICA DE TRATAMIENTO DE DATOS PERSONALES DE FRANCA AGENCY.</span>
                                                </Link>
                                            </FormGroup>
                                        </Col>
                                        <Col md={9} style={{ textAlign: "right" }}>
                                            <FormGroup>
                                                <Button type="submit" className="JoinSubmitBtn">ENVIAR</Button>
                                            </FormGroup>
                                        </Col>
                                    </Form>
                                </div>
                                {/* <div className="closeBTN" onClick={closeModalJoin}>
                                    <img className="JoinCloseBTNPic" id="JoinCloseBTN" src="/assets/icons/CloseIcon.png" alt="" />
                                    <p className="JoinCloseText">CLOSE</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}