import React, { Component } from "react";
import Modal from 'react-modal';
import BlogForm from '../../../../Forms/blogForm';

import './Blog_1.css';

import { data } from '../../data';

export default class Blog1 extends Component {
    constructor() {
        super();
        this.state = {
            showModalBlog: false,
        };
        this.handleOpenModalBlog = this.handleOpenModalBlog.bind(this);
        this.handleCloseModalBlog = this.handleCloseModalBlog.bind(this);
    }
    handleOpenModalBlog() {
        this.setState({ showModalBlog: true });
    }

    handleCloseModalBlog() {
        this.setState({ showModalBlog: false });
    }
    render() {
        let id = 0;
        return (
            <React.Fragment>
                <div className="InnerBanner">
                    <img src={data[id].Banner} alt="" width="100%" />
                    <p className="InnerTitle Akzidenz-Roman">
                        {data[id].Title}
                    </p>
                </div>
                <div className="Blog_1_Body">
                    <div className="Blog_1_TextBox">
                        <img className="Blog_1_Pic" src={data[id].Image1} alt="" />
                        <div className="Blog_1_text">
                            <p className="Blog_1Subtext Freight-light">
                                La Industria de la <br /><span className="verde Freight-book">belleza</span> y la <span className="verde Freight-book">cosmética</span><br /><span className="WorkSans-Medium"> DENTRO DE LA <br />REVOLUCIÓN DIGITAL</span>
                            </p>
                            <p className="WorkSans f18pt">
                                {data[id].Text1}
                            </p>
                            <p className="WorkSans f18pt">
                                {data[id].Text2}
                            </p>
                            <button className="pptBTN WorkSansLight" onClick={this.handleOpenModalBlog}>
                                DESCARGAR INFORME
                                <img src="/assets/icons/download.png" alt="" />
                            </button>
                        </div>
                    </div>
                    <Modal
                        animation={false}
                        isOpen={this.state.showModalBlog}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.handleCloseModalBlog}
                        className="ModalBlog"
                        overlayClassName="OverlayBlog"
                    >
                        <BlogForm closeModalBlog={this.handleCloseModalBlog} id={id} />
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}