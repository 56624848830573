import React, { } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Main from './components';

import './App.css';
import Inner from './components/Blog/News/Inner/Inner';
import Menu from './components/Menu/Menu';
/* import Cases from './components/Cases/Cases'; */
/* import ContactUS from './components/ContacUS/ContactUS'; */
/* import FamiliarFace from './components/FamiliarFace/FamiliarFace'; */
import ScrollToTop from './components/ScrollTop/ScrollToTop';
import Blog from './components/Blog/Blog';
import BlogAdmmin from './components/BlogAdmin/BlogAdmin';
import Thanks from './components/Thanks/Thanks';
import TYC from './components/TYC/tyc';
import TrabajeNosotros from './components/TrabajeNosotros/TrabajeNosotros';
function App() {
  window.onscroll = function () {
    scrollRotate();
    scrollText();
  }
  function scrollRotate() {
    let image = document.getElementById("Menuicon");
    /* let cinta = document.getElementById("cinta"); */
    let w = window;
    let y = w.innerHeight;
    /* cinta.style.transform = "translate(0px," + (w.pageYOffset-(y*(200)/100))/1.2 + "px)"; */
    if (image) {
      image.style.transform = "rotate(" + (w.pageYOffset) / 10 + "deg)";
      if (((w.pageYOffset) > (y * (200 - 7)) / 100) && ((w.pageYOffset) < (y * (570 - 7)) / 100)) {
        image.src = "../assets/icons/MenuBtnBlack.png"
      } else if ((w.pageYOffset) > (y * (670 - 7)) / 100 && (w.pageYOffset) < (y * (1200 - 7)) / 100) {
        image.src = "../assets/icons/MenuBtnBlack.png";
      } else {
        image.src = "../assets/icons/MenuBtn.png";
      }
    }
  }
  function scrollText() {
    let hScreen = window.innerHeight;
    let pos = window.pageYOffset;
    let text = document.getElementById("AboutText");
    let x = document.getElementById("FirstWord");
    let xx = document.getElementById("SecondWord");
    let xxx = document.getElementById("LastWord");
    let create = document.getElementById("HomeTextBox");
    let createMov = document.getElementById("HomeMovTextBox");
    let colorText = 1.10 * hScreen;
    let colorMovText = 0.8 * hScreen;
    let limit = 0.91;
    let textMov = document.getElementById("AboutMovText");
    let m = document.getElementById("FirstMovWord");
    let mm = document.getElementById("SecondMovWord");
    let mmm = document.getElementById("LastMovWord");
    let MovLimit = 0.99;
    let face = document.getElementById("FamiliarText");
    let mface = document.getElementById("FamiliarMovText");
    let agencyTitle = document.getElementById("agencyTitle");
    let agencyFirstText = document.getElementById("agencyFirsText");
    let agencySecondText = document.getElementById("agencySecondText");
    let agencyThirdText = document.getElementById("agencyThirdText");
    if (text && x && xx && xxx && create && createMov && textMov && m && mm && mmm && face && mface && agencyTitle && agencyFirstText && agencySecondText && agencyThirdText) {
      if (pos > (8 * hScreen) && pos < (9 * hScreen)) {
        face.style.transform = "translate(0px," + ((9 * hScreen) - pos) + "px)";
      } else {
        face.style.transform = "translate(0px," + 0 + "px)";
      }
      if (pos > (10 * hScreen) && pos < (13.5 * hScreen)) {
        mface.style.transform = "translate(0px," + ((13 * hScreen) - (pos + 50)) + "px)";
      } else {
        mface.style.transform = "translate(0px," + 0 + "px)";
      }
      if (pos > (-10) && pos < (limit * hScreen)) {
        if (pos < (0.48 * hScreen)) {
          create.style.display = "none";
        } else {
          create.style.display = "block";
        }
        create.style.transform = "translate(0px," + pos + "px)";
      } else {
        create.style.transform = "translate(0px," + (limit * hScreen) + "px)";
      }
      /*Cell*/
      if (pos > (-10) && pos < (MovLimit * hScreen)) {
        createMov.style.transform = "translate(0px," + pos + "px)";
      } else {
        createMov.style.transform = "translate(0px," + (MovLimit * hScreen) + "px)";
      }
      if (pos > (0.1 * hScreen) && pos < (0.8 * hScreen)) {
        text.style.transform = "translate(0px," + ((1.55 * hScreen) - pos) / 2 + "px)";
      } else {
        text.style.transform = "translate(0px," + (0.2 * hScreen) + "px)";
      }
      if (pos > (0.1 * hScreen) && pos < (0.8 * hScreen)) {
        textMov.style.transform = "translate(0px," + ((1.2 * hScreen) - pos) / 2 + "px)"
      } else {
        textMov.style.transform = "translate(0px," + (0.15 * hScreen) + "px)"
      }
      if (colorText > pos) {
        /* SOMOS */
        x.style.color = "#34DB8D";
        x.style.textTransform = "uppercase";
        xx.style.color = "white";
        xx.style.textTransform = "lowercase";
        xxx.style.color = "white";
        xxx.style.textTransform = "lowercase";
      } else if (colorText > (pos - 100)) {
        /* ALIADO */
        x.style.color = "white";
        x.style.textTransform = "none";
        xx.style.color = "#34DB8D";
        xx.style.textTransform = "uppercase";
        xxx.style.color = "white";
        xxx.style.textTransform = "lowercase";
      } else {
        /* MARKETING */
        x.style.color = "white";
        x.style.textTransform = "none";
        xx.style.color = "white";
        xx.style.textTransform = "lowercase";
        xxx.style.color = "#34DB8D";
        xxx.style.textTransform = "uppercase";
      }
      if (colorMovText > pos) {
        /* SOMOS */
        m.style.color = "#34DB8D";
        m.style.textTransform = "uppercase";
        mm.style.color = "white";
        mm.style.textTransform = "lowercase";
        mmm.style.color = "white";
        mmm.style.textTransform = "lowercase";
      } else if (colorMovText > (pos - 100)) {
        /* ALIADO */
        m.style.color = "white";
        m.style.textTransform = "capitalize";
        mm.style.color = "#34DB8D";
        mm.style.textTransform = "uppercase";
        mmm.style.color = "white";
        mmm.style.textTransform = "lowercase";
      } else {
        /* MARKETING */
        m.style.color = "white";
        m.style.textTransform = "capitalize";
        mm.style.color = "white";
        mm.style.textTransform = "lowercase";
        mmm.style.color = "#34DB8D";
        mmm.style.textTransform = "uppercase";
      }
      if ((1.75 * hScreen) > pos) {
        agencyTitle.style.display = "block";
        agencyFirstText.style.display = "block";
        agencySecondText.style.display = "none";
        agencyThirdText.style.display = "none";
      } else if ((1.75 * hScreen) > (pos - 100)) {
        agencyTitle.style.display = "block";
        agencyFirstText.style.display = "none";
        agencySecondText.style.display = "block";
        agencyThirdText.style.display = "none";
      } else {
        agencyTitle.style.display = "block";
        agencyFirstText.style.display = "none";
        agencySecondText.style.display = "none";
        agencyThirdText.style.display = "block";
      }

    }

  }
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <ScrollToTop />
          <Menu />
          {/* <a className="chat" href="/home#ContactUS">
            <img src="/assets/icons/chat.png" alt="chat" />
          </a> */}
          <Switch>
            <Route path="/home" component={Main}></Route>
            <Route path="/blog" component={Blog}></Route>
            <Route path="/news" component={Inner}></Route>
            <Route path="/blog-admin" component={BlogAdmmin}></Route>
            {/* <Route path="/cases" component={Cases}></Route> */}
            <Route path="/thanks" component={Thanks}></Route>
            <Route path="/tyc" component={TYC}></Route>
            <Route path="/Trabaje_con_nosotros" component={TrabajeNosotros}></Route>
            <Redirect to="/home"></Redirect>

          </Switch>
          {/* <ContactUS />
          <FamiliarFace /> */}
        </Router>
      </header>
    </div>
  );
}

export default App;
