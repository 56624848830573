import React, { Component } from 'react';

import Modal from 'react-modal';
import AllieForm from '../Forms/AllieForm';
import AllieMovForm from '../Forms/AllieMovForm';
import JoinForm from '../Forms/JoinForm';
import JoinMovForm from '../Forms/JoinMovForm';
import { Link } from 'react-router-dom';
import './ContactUS.css'

export default class ContactUS extends Component {
   constructor() {
      super();
      this.state = {
         showModalAllie: false,
         showModalJoin: false
      };
      this.handleOpenModalAllie = this.handleOpenModalAllie.bind(this);
      this.handleCloseModalAllie = this.handleCloseModalAllie.bind(this);
      this.handleOpenModalJoin = this.handleOpenModalJoin.bind(this);
      this.handleCloseModalJoin = this.handleCloseModalJoin.bind(this);
   }

   handleOpenModalAllie() {
      /*let x= document.getElementById("ContactBox");
      x.style.backgroundImage = "none"
      x.style.backgroundColor = "rgba(29,29,27,0.4)" */
      this.setState({ showModalAllie: true });
      setTimeout(() => {
         document.getElementById("AllieCloseBTN").style.transform = "rotate(00deg)";
      }, 50);
   }

   handleCloseModalAllie() {
      this.setState({ showModalAllie: false });
   }

   handleOpenModalJoin() {
      this.setState({ showModalJoin: true });
      setTimeout(() => {
         document.getElementById("JoinCloseBTN").style.transform = "rotate(00deg)";
      }, 50);
   }

   handleCloseModalJoin() {
      /*let x= document.getElementById("ContactBox");
      /* x.style.backgroundImage = "url(/static/media/ContactUS-01.81542075.png)"
      x.style.backgroundColor = "transparent" */
      this.setState({ showModalJoin: false });
   }

   render() {
      return (
         <React.Fragment>
            <div id="ContactUS">
               <div className="ContactBox Background" id="ContactBox">
                  <video className="video" id="Video2" autoPlay loop muted>
                     <source src="#" type="video/mp4" />
                  </video>
                  <div className="contactLogo">
                     <img src="/assets/img//logos/Franca.png" alt="Franca" />
                  </div>
                  <div className="ContactTitle">
                     <p className="Akzidenz-Roman ">
                        <span className="firstText"> LET'S </span>
                        <span className="secondText"> talk! </span>
                     </p>
                  </div>
                  <div className="container" style={{ position: "relative" }}>
                     <div className="row justify-content-around ContactLogos">
                        <div className="col-md-2 AllieIMG">
                           <img className="imagen" alt="Hablemos" onClick={this.handleOpenModalAllie} />
                           <p>HABLEMOS DE TU MARCA</p>
                        </div>
                        <div className="col-md-2 JoinUsIMG">
                        <Link to={{ pathname: "/Trabaje_con_nosotros", }} target="_blank"><img className="imagen" alt="Se talento" /></Link>
                           <p>SÉ UN TALENTO FRANCA</p>
                        </div>
                     </div>
                     <Modal
                        isOpen={this.state.showModalAllie}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.handleCloseModalAllie}
                        className="ModalAllie"
                        overlayClassName="OverlayAllie"
                     >
                        <AllieForm closeModalAllie={this.handleCloseModalAllie} />
                        <AllieMovForm closeModalAllie={this.handleCloseModalAllie} />
                     </Modal>
                     {/* <Modal
                        animation={false}
                        isOpen={this.state.showModalJoin}
                        contentLabel="onRequestClose Example"
                        onRequestClose={this.handleCloseModalJoin}
                        className="ModalJoin"
                        overlayClassName="OverlayJoin"
                     >
                        <JoinForm closeModalJoin={this.handleCloseModalJoin} />
                        <JoinMovForm closeModalJoin={this.handleCloseModalJoin} />
                     </Modal> */}
                     <div className="row  justify-content-center francaData">
                        <div className="row col-11 justify-content-around francaDataBox FreightBook">
                           <div className="col-md-3 align-self-center">
                              <p className="francaDataText"><i>hola@franca.agency</i></p>
                           </div>
                           <div className="col-md-4 align-self-center">
                              <p className="francaDataText">+57 (1)745 40 22 - 312 466 3076</p>
                           </div>
                           <div className="col-md-3 align-self-center">
                              <p className="francaDataText">Bogotá, <i>Colombia</i></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}