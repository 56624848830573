import React, { Component } from 'react';
import { data } from '../Blog/News/data';

import './Thanks.css'

export default class Thanks extends Component {
    render() {
        const { state } = this.props.location.state;
        return (
            <div className="mainThanksBox">
                <p>
                    Gracias por ponerte en contacto con nosotros tu presentacion esta en el siguiente enlace: <br/>
                    id:  {state}
                </p>
                <a href={data[state].PDF} target="blank">
                    Descargar
                </a>
            </div>
        );
    }
}