import React, { Component } from 'react';
/* import { FadeTransform } from 'react-animation-components'; */

import './FamiliarFace.css';

export default class FamiliarFaceWeb extends Component {
   render() {
      return (
         <React.Fragment>
            <div className="FamiliarFaceBox">
               <div className="LogoFamiliarFace">
                  <img src="/assets/img//logos/FrancaFull.png" alt="Franca" />
               </div>
               <div className="FullFamiliarText">
                  <div className="firstText Akzidenz-Roman FamiliarText" id="FamiliarText">
                     <p>a familiar face</p>
                  </div>
                  <div className="secondText Freight-Light">
                     <p>in the digital world</p>
                  </div>
               </div>
               <div className="FooterBox">
                  <div>
                     <p className="HashFranca">#Team<span>Franca</span></p>
                  </div>
                  <div className="row col-12 justify-content-center ">
                     <div className="row col-11 justify-content-around francaDataFaceBox FreightBook">
                        <div className="col-md-3 align-self-center francaData">
                           <img src="/assets/icons/InstagramBlack.png" alt="" />
                           <span className="francaDataText Akzidenz"> INSTAGRAM: <a href="https://www.instagram.com/franca.agency/" target="blank" className="Freight-Italic socialNet">franca.agency</a> </span>
                        </div>
                        <div className="col-md-4 align-self-center francaData">
                           <img src="/assets/icons/FacebookBlack.png" alt="" />
                           <span className="francaDataText Akzidenz"> FACEBOOK: <a href="https://www.facebook.com/franca.agency/" target="blank" className="Freight-Italic socialNet">Franca.agency</a></span>
                        </div>
                        <div className="col-md-3 align-self-center francaData">
                           <img src="/assets/icons/LinkedinBlack.png" alt="" />
                           <span className="francaDataText Akzidenz"> LINKEDIN: <a href="https://www.linkedin.com/company/franca-agency" target="blank" className="Freight-Italic socialNet">Franca.Agency</a></span>
                        </div>
                     </div>
                  </div>
                  <div className="copyright">
                     <p>2021 © TODOS LOS DERECHOS RESERVADOS</p>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}