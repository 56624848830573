import React, { Component, Suspense } from 'react';
import { FadeTransform } from 'react-animation-components';
import { Loading } from '../Loading/Loading';

import './Home.css';

export default class Home extends Component {

   render() {
      return (
         <React.Fragment>
            <div className="MainWebBox" >

               <Suspense fallback={<Loading />}>
                  <section>
                     <video className="video" id="Video" autoPlay loop muted>
                        <source src="/assets/vid/FrancaWeb.mp4" type="video/mp4" />
                     </video>
                  </section>
               </Suspense>
               <div className="LogoBox">
                  <img className="FrancaLogo"
                     src="/assets/img/logos/FrancaHome.png"
                     alt="franca"
                  />
               </div>
               <div className="container col-12 justify-content-center HomeTextBox ocultar" id="HomeTextBox">
                  <div className="row justify-content-center">
                     <div className="col-6 firstTextBox">
                        <FadeTransform in
                           transformProps={{
                              enterTransform: 'translateY(00vh)',
                              exitTransform: 'translateY(100vh)',
                              duration: "2000"
                           }}>
                           <p className="firstText Akzidenz-Roman ocultar">
                              CREATING
                           </p>
                        </FadeTransform>
                     </div>
                     <div className="col-5 secondTextBox">
                        <span className="secondText Freight-Light ocultar">
                           the future
                        </span>
                     </div>
                  </div>
               </div>
               <div className="ScrollRow">
                  <img src="/assets/img/ScrollRow.png" alt="Scroll" />
               </div>
            </div>
            {/*cell*/}
            <div className="MainMovBox">
               <div className="LogoBox">
                  <img className="FrancaMovLogo"
                     src="/assets/img/logos/FrancaHome.png"
                     alt="Franca"
                  />
                  <div className="container col-12 HomeMovTitle">
                     <div className="row HomeMovTextBox" id="HomeMovTextBox">
                        <div className="col-12 firstTextBox">
                           <FadeTransform in
                              transformProps={{
                                 enterTransform: 'translateY(00vh)',
                                 exitTransform: 'translateY(100vh)',
                                 duration: "2000"
                              }}>
                              <p className="firstText Akzidenz-Roman">
                                 CREATING
                              </p>
                           </FadeTransform>
                        </div>
                        <div className="col-12 secondTextBox">
                           <span className="secondText FreightLight">
                              the future
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}