import React, { Component } from 'react';

import './FamiliarFace.css';

export default class FamiliarFaceMov extends Component {
   render() {
      return (
         <React.Fragment>
            <div className="FamiliarFaceMovBox">
               <div className="LogoFamiliarFace">
                  <img src="/assets/img//logos/FrancaFull.png" alt="Franca" />
               </div>
               <div className="FullFamiliarText">
                  <div className="firstText Akzidenz-Roman FamiliarText" id="FamiliarMovText">
                     <p>a familiar </p>
                  </div>
                  <div className="secondText Freight-Light FamiliarSubText">
                     <p>face in the digital world</p>
                  </div>
               </div>
               <div className="FooterBox">
                  <div>
                     <p className="HashFranca">#TeamFranca</p>
                  </div>
                  <div className="row  justify-content-center francaData">
                     <div className="row col-11 justify-content-around francaDataBox FreightBook">
                        <div className="col-md-3 align-self-center">
                           <img src="/assets/icons/InstagramBlack.png" alt="" />
                           <span className="francaDataText2 Akzidenz">INSTAGRAM: <span className="Freight-Italic">franca.agency</span> </span>
                        </div>
                        <div className="col-md-4 align-self-center">
                           <img src="/assets/icons/FacebookBlack.png" alt="" />
                           <span className="francaDataText2 Akzidenz">FACEBOOK: <span className="Freight-Italic">Franca.agency</span></span>
                        </div>
                        <div className="col-md-3 align-self-center">
                           <img src="/assets/icons/LinkedinBlack.png" alt="" />
                           <span className="francaDataText2 Akzidenz">LINKEDIN: <span className="Freight-Italic">Franca.Agency</span></span>
                        </div>
                     </div>
                  </div>
                  <div className="copyright">
                     <p>2021 © TODOS LOS DERECHOS RESERVADOS</p>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}