import React, { Component } from 'react';

import './Menu.css';

export default class Menu extends Component {
   constructor(props) {
      super(props);
      this.state = {
      };
      this.openNav = this.openNav.bind(this);
      this.closeNav = this.closeNav.bind(this);
   }
   openNav() {
      document.getElementById("myNav").style.width = "100%";
      document.getElementById("myNav").style.height = "100%";
      document.getElementById("CloseBTN").style.transform = "rotate(0deg)";
   }
   closeNav() {
      document.getElementById("myNav").style.width = "0%";
      document.getElementById("myNav").style.height = "0%";
      document.getElementById("CloseBTN").style.transform = "rotate(90deg)"
   }

   render() {
      return (
         <React.Fragment>
            <div id="myNav" className="overlay"
               style={{
                  background: "url(/assets/img/Background/MenuBack.png)",
                  backgroundSize: "cover",
               }}
            >
               <span className="closebtn" onClick={this.closeNav}>
                  <img src="/assets/icons/CloseIcon.png" alt="" id="CloseBTN" />
                  <p className="CloseText">CLOSE</p>
               </span>
               <span>
                  <img className="logoMenu" src="/assets/img/logos/logoB.png" alt="Franca" />
               </span>
               <div className="overlay-content">
                  <ul>
                     <a href="/home" onClick={this.closeNav} >HOME</a>
                     {/* <a href="/cases" onClick={this.closeNav}  >CASOS</a> */}
                     <a href="/home#Blog" onClick={this.closeNav}>BLOG</a>
                     <a href="/home#Allies" onClick={this.closeNav} >CLIENTES</a>
                     {/* <a href="#Talent" onClick={this.closeNav} >TALENTO</a> */}
                     <a href="/home#ContactUS" onClick={this.closeNav} >CONTACTO</a>
                  </ul>
               </div>
               <div className="MenuSocial">
                  {/*                   <ul> */}
                  <div className="linkedin row col-12 justify-content-end">
                     <div className="col-" style={{ textAlign: "right", padding: "0% 5% 0% 0%" }}>
                        <span>Franca Agency</span>
                     </div>
                     <div className="col-1" style={{ padding: "2% 0% 0% 0%" }}>
                        <a href="https://www.linkedin.com/company/franca-agency" className="linkedinIcon" target="blank">L</a>
                     </div>
                  </div>
                  <div className="face row col-12 justify-content-end">
                     <div className="col-" style={{ textAlign: "right", padding: "0% 5% 0% 0%" }}>
                        <span>Franca.agency</span>
                     </div>
                     <div className="col-1 align-self-end" style={{ padding: "3% 6% 3% 1.5%" }}>
                        <a href="https://www.facebook.com/franca.agency/" className="" target="blank">F</a>
                     </div>
                  </div>
                  <div className="insta row col-12 justify-content-end">
                     <div className="col-" style={{ textAlign: "right", padding: "0% 5% 0% 0%" }}>
                        <span>franca.agency</span>
                     </div>
                     <div className="col-1 align-self-end" style={{ padding: "0% 0% 1% 0%" }}>
                        <a href="https://www.instagram.com/franca.agency/" className="" target="blank">I</a>
                     </div>
                  </div>
                  {/*                   </ul> */}
               </div>
            </div>
            <span className="NavBtn" id="MenuIcon"
               onClick={this.openNav}>
               <img id="Menuicon" src="../assets/icons/MenuBtn.png" alt="" />
            </span>
         </React.Fragment>
      );
   }
}