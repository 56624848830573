import React, { Component, Suspense } from 'react';
import '../App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

/* import Menu from './Menu/Menu'; */
import Home from './Home/Home';
import AboutUs from './AboutUs/AboutUs';
/* import Cases from './Cases/Cases'; */
import Allies from './Allies/Allies';
import { Loading } from './Loading/Loading';
import ContactUS from './ContacUS/ContactUS';
import FamiliarFace from './FamiliarFace/FamiliarFace';
import Agency from './Agency/Agency';
import Blog from './Blog/Blog';
import Tyc from './TYC/tyc';
/* const Home = React.lazy(() => import('./Home/Home')); */
/* const AboutUs = React.lazy(()=> import('./AboutUs/AboutUs')); */
/* const Cases = React.lazy(() => import('./Cases/Cases')); */
/* const Allies = React.lazy(() => import('./Allies/Allies')); */
/* const Talent = React.lazy(()=> import('./Talent/Talent')); */
/* const ContactUS = React.lazy(()=> import('./ContacUS/ContactUS')); */
/* const FamiliarFace = React.lazy(()=> import('./FamiliarFace/FamiliarFace')); */
export default class Main extends Component{
   render(){
      
      return(
            <React.Fragment>
               <div style={{}}>
                  {/* <Menu /> */}
                  {/* <Home/> */}
                  <Suspense delayMs={1} maxDuration={10000} fallback={<Loading/>} >
                     <section>
                        <Home/>
                        <AboutUs/>
                        <Agency/>
                        {/* <Cases /> */}
                        <Allies/>
                        <Blog/>
                        {/* <Talent/> */}
                        <ContactUS/>
                        <FamiliarFace/>
                     </section>
                  </Suspense>
               </div>
            </React.Fragment>
      );
   }
}