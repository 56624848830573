import React, { Component } from 'react';

import './Allies.css';

export default class AlliesWeb extends Component{
   render(){
      return(
         <React.Fragment>
            <div className="container col-12 alliesMainBox ">
               <video className="video" id="Video1" autoPlay loop muted>
                  <source src="#" type="video/mp4" />
               </video>
               <div className="logoFranca row justify-content-center">
                  <img className="logoFrancaAllies" src="/assets/img/logos/logoW.png" alt="" />
               </div>
               <div className="alliesTitle">
                  <span className="Italic">Connecting </span>
                  <span className="alliesSubTitle AkzidenText">PEOPLE </span>
                  <span className="alliesEndTitle AkzidenText">WITH OUR CLIENTS</span>
               </div>
               <div className="logosBlock container justify-content-center col-9">
                  <div className="row justify-content-center">
                     <div className="col-3 AlliePicBoxNov" >
                        <img className="AlliePic" 
                        src="/assets/img/logos/NovoNordisk.png" alt="NOVO NORDISK"
                        />
                     </div>
                     <div className="col-3 AlliePicBoxMerz">
                        <img className="AlliePic" src="/assets/img/logos/MerzAesthetics.png" alt="MERZ AESTHETICS" />
                     </div>
                     <div className="col-3 AlliePicBoxFlips">
                        <img className="AlliePic" src="/assets/img/logos/Flips.png" alt="FLIPS" />
                     </div>
                  </div>
                  <div className="row justify-content-center">
                     <div className="col-3 AlliePicBoxAbbvie">
                        
                     </div>
                     <div className="col-3 AlliePicBoxGalderma">
                        <img className="AlliePic" src="/assets/img/logos/Galderma.png" alt="GALDERMA" />
                     </div>
                     <div className="col-3 AlliePicBoxJaibel">
                        <img className="AlliePic" src="/assets/img/logos/Jaibel.png" alt="JAIBEL" />
                     </div>
                  </div>
                  <div className="row justify-content-center">
                     <div className="col-3 AlliePicBoxAllergan">
                        <img className="AlliePic" src="/assets/img/logos/Allergan.png" alt="Allergan" />
                     </div>
                     <div className="col-3 AlliePicBoxSanfer">
                        
                     </div>
                     <div className="col-3 AlliePicBoxCoandes">
                        <img className="AlliePic" src="/assets/img/logos/Coandes.png" alt="COANDES" />
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}
/* onMouseEnter={e => (console.log(e))}
   onMouseOver={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordisk.png)" 
         )}
   onMouseOut={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordiskBlack.png)" )} 
*/