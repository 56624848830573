import React, { Component } from 'react';

import './Allies.css';
import AlliesMov from './AlliesMov';
import AlliesWeb from './AlliesWeb';

export default class Allies extends Component{
   render(){
      return(
         <React.Fragment>
            <div id="Allies">
               
               <div className="AlliesWeb">
                  <AlliesWeb />
               </div>
               <div className="AlliesMov">
                  <AlliesMov />
               </div>
            </div>
         </React.Fragment>
      );
   }
}
/* onMouseEnter={e => (console.log(e))}
   onMouseOver={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordisk.png)" 
         )}
   onMouseOut={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordiskBlack.png)" )} 
*/