import React from 'react';

import './Blog.css'
import News from './News/News';

export default function BlogWeb() {
   /*const [blogs, setBlogs] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   const handleDelete = (id) =>{
      const newBlogs = blogs.filter(blog => blog.id !== id  );
      setBlogs(newBlogs);
   } */
   /* useEffect(() => {
      fetch('http://localhost:8000/news')//cambiar la url por la del api//
         .then(res => {
            return res.json()
         })
         .then((data) => {
            console.log(data)
            setBlogs(data)
            setIsLoading(false)
         }).catch((err)=>{
            console.log(err)
         }
         )
   }, []); */
   return (
      <React.Fragment>
         <div className="BlogMainWebBox">
            <p className="PlayFair BlogTitle" >
               El hogar de nuestras
            </p>
            <p className="Akzidenz-Roman">
               <span className="firstBlogText">
                  ÚLTIMAS
               </span>
               <span className="secondBlogText" >
                  IDEAS
               </span>
            </p>
            <div>
               {/* {isLoading && <div> Loading...</div> } */}
               {/* {blogs && <News blogs={blogs} handleDelete={handleDelete} />} */}
               {/* <News /> */}
               <iframe id="inlineFrameExample"
                  title="Inline Frame Example"
                  width="100%"
                  height="576px"
                  src="https://franca.agency/blog/">
               </iframe>
            </div>
         </div>
      </React.Fragment>
   );
}