import React, { Component } from 'react';

import './FamiliarFace.css'
import FamiliarFaceMov from './FamiliarFaceMov';
import FamiliarFaceWeb from './FamiliarFaceWeb';

export default class FamiliarFace extends Component{
   render(){
      return(
         <React.Fragment>
            <FamiliarFaceWeb/>
            <FamiliarFaceMov/>
         </React.Fragment>
      );
   }
}