import React from 'react';

import './Loading.css'

export const Loading = () => {
   return (
      <div className="col-12 LoadBox justify-content-center">
         <img className="LoadPic" src="/assets/icons/LoadPage.png" alt="Loading..." />
         {/* <p>Loading...</p> */}
      </div>
   );
}