import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import './News.css'

/* import { data } from './data'; */

export default class Product extends Component {
   render() {
      const { id } = this.props;
      let BGColor = "";
      let data = this.props.blogs;
      let Foto = data[id].Image2;
      if ((id % 2)) {
         BGColor = "#FF80E6"/*Rosado*/
      } else {
         BGColor = "#34DB8D"
      }
      return (
         <React.Fragment>
            <Card className="CardStyle" style={{
               backgroundImage: 'url(' + Foto + ')',
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover"
            }}>
               <CardText className="TitleBox titleFont">
                  <CardTitle>
                     {data[id].Title}
                  </CardTitle>
               </CardText>
               {/* <CardImg className="BlogPic" src={data[id].Image} alt="" height="100%" /> */}
               <CardText className="TextBox" style={{ backgroundColor: BGColor }}>
                  <CardBody>
                     <p className="PreText">
                        {
                           data[id].PreText
                        }
                     </p>
                     <Link to={{
                        pathname: "/news",
                        state: {
                           id: id,
                        }
                     }}>
                        <img src="/assets/img/Blog/Leer.png" alt="Leer" />
                     </Link>
                  </CardBody>
               </CardText>
            </Card>
         </React.Fragment>
      );
   }
}