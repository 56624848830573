import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './AllieForm.css';

export default class AllieForm extends Component {
   /* constructor(props){
      super(props);
      this.state={
         name:"",
         email:"",
         message:"",
      }
   } */
   handleSubmit(e) {
      e.preventDefault();
      axios.post('/correo/', {
         name: e.target.name.value,
         email: e.target.email.value,
         message: e.target.Message.value,
         agree: e.target.Agree.value,
      }).then((response) => {
         debugger;
         if (response.data.status === 'success') {
            alert("Message Sent.");
            e.target.name.value = " ";
            e.target.email.value = " ";
            e.target.Message.value = " ";
            e.target.Agree.value = " ";
         } else if (response.data.status === 'fail') {
            alert("Message failed to send.")
         }
      })

   }

   render() {
      let closeModalAllie = this.props.closeModalAllie;
      return (
         <React.Fragment>
            <div className="AllieFormBox">
               <div className="row justify-content-center ">
                  <div className="AlliesFormBox col-7">
                     <div className="row">
                        <div className="col-3">
                           <img src="/assets/img/Forms/AlliesForm.png" alt="logo" />
                        </div>
                        <div className="col-8 ">
                           <Form className="row" onSubmit={(e) => this.handleSubmit(e)}>
                              <Label className="AlliesTitle AkzidenzGroteskCE-Roman">
                                 Estás a un paso de conocer tu nuevo
                              </Label>
                              <Label className="AlliesSubTitle AkzidenzGroteskCE-Roman">
                                 ALIADO ESTRATÉGICO
                              </Label>
                              <Col md={8}>
                                 <FormGroup>
                                    <Input type="name" name="name" id="name"
                                       placeholder="Nombre"
                                       style={{
                                          backgroundColor: "#1D1D1B",
                                          color: "white"
                                       }} />
                                 </FormGroup>
                                 <FormGroup>
                                    <Input type="email" name="email" id="email" placeholder="E-mail"
                                       style={{
                                          backgroundColor: "#1D1D1B",
                                          color: "white"
                                       }} />
                                 </FormGroup>
                              </Col>
                              <Col md={8}>
                                 <FormGroup>
                                    <Input type="textarea" name="Message" id="Message" rows="4" placeholder="¿En qué podemos ayudarte?"
                                       style={{
                                          backgroundColor: "#1D1D1B",
                                          color: "white"
                                       }} />
                                 </FormGroup>
                              </Col>
                              <Col md={12} style={{ textAling: "left" }}>
                                 <FormGroup>
                                    <Input className="form2" type="checkbox" name="Agree" value="Autorizo a la Política de Tratamiento de Datos Personales de FRANCA AGENCY" />
                                    <Link to={{
                                       pathname: "/tyc",
                                    }} target="_blank" style={{color:"#FFFFFF"}}>
                                       <span className="Terminos_condiciones">AUTORIZO A LA POLITICA DE TRATAMIENTO DE DATOS PERSONALES DE FRANCA AGENCY.</span>
                                    </Link>
                                 </FormGroup>
                              </Col>
                              <Col md={8} style={{ textAlign: "right" }}>
                                 <FormGroup>
                                    <Button type="submit" className="AllieSubmitBtn">
                                       ENVIAR
                                    </Button>
                                 </FormGroup>
                              </Col>
                           </Form>
                        </div>
                        <div className="closeBTN" onClick={closeModalAllie}>
                           <img className="AllieCloseBTNPIC" src="/assets/icons/CloseIcon.png" alt=""
                              id="AllieCloseBTN" />
                           <p className="AllieCloseText">CLOSE</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}