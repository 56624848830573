import React, { Component, Suspense } from 'react';
import Footer from '../FamiliarFace/FamiliarFace'
import './tyc.css';
import ImgTyc from "../../assets/img/Banner_Blog1.png";
export default class tyc extends Component {
    render() {

        return (
            <React.Fragment>
                <div className="InnerBanner">
                    <img src={ImgTyc} alt="" width="100%" />
                    <p className="InnerTitle Akzidenz-Roman">
                        Politica de tratamiento de datos pesonales
                    </p>
                </div>
                <div className="Blog_1_Body">
                    <p className="Parrafo1">
                        1. AVISO DE PRIVACIDAD Y CONTEXTO LEGAL
                    </p>
                    <p className="parrafo">
                        Mediante la Ley 1581 de 2012 se expidió el Régimen General de Protección de
                        Datos Personales, específicamente en los artículos 17 literal “K” y 18 literal “F”,
                        artículo 13 del Decreto 1377 de 2013, el cual reglamenta la ley anterior, artículo 15
                        de la Constitución Política Colombiana.
                        El presente aviso de privacidad de tratamiento de Datos Personales, será aplicable
                        a la totalidad de datos recogidos por FRANCA AGENCY S.A.S., en adelante “EL
                        RESPONSABLE”.
                    </p>

                    <p className="Parrafo1">
                        2.RESPONSABLE DEL TRATAMIENTO DE DATOS
                    </p>
                    <p className="Parrafo">
                        Las bases de datos objeto de la presente política son responsabilidad de FRANCA
                        AGENCY S.A.S., identificada con NIT. 901.095.771, con domicilio principal en la
                        Calle 98 # 10-41 en Bogotá D.C.
                    </p>

                    <p className="Parrafo1">
                        3. FINALIDAD DEL TRATAMIENTO DE LA INFORMACIÓN
                    </p>
                    <p className="Parrafo">
                        Los datos recogidos, usados y almacenados por El Responsable tienen como
                        propósito permitir el adecuado desarrollo del objeto social de la compañía,
                        estableciendo contacto directo con clientes potenciales mediante los medios
                        electrónicos disponibles para tal fin.
                        Por otro lado, el tratamiento se referirá directamente con lo que el titular de la
                        información expresamente autorice, especialmente en lo relacionado con:
                    </p>
                    <ul className="ListaParagrafoTres">
                        <li>
                            Informar al titular sobre los servicios previstos en el portafolio de la
                            Compañía que puedan ser de su interés, cambios en los canales de
                            atención y demás novedades que puedan deban ser de su conocimiento.
                        </li>
                        <li>
                            Solicitar su autorización expresa previo a la realización de un contacto
                            directo para conocer las necesidades que puedan ser cubiertas por los
                            servicios que se ofrecen.
                        </li>
                        <li>
                            Y las demás finalidades requeridas en desarrollo del objeto social.
                        </li>
                    </ul>
                    <p className="Parrafo1">
                        4. FINALIDAD DEL TRATAMIENTO DE LA INFORMACIÓN
                    </p>
                    <p className="Parrafo">
                        El tratamiento de datos considerados sensibles se realizará previo consentimiento
                        expreso del titular de la información con apego a las normas que regulan la
                        materia.
                        En ningún caso, el titular de los datos estará obligado a autorizar el tratamiento de
                        la información cuyo uso pueda afectar su intimidad o generar consecuencias que lo
                        afecten de forma negativa
                    </p>
                    <p className="Parrafo1">
                        5. DATOS PERSONALES DE LOS MENORES DE EDAD
                    </p>
                    <p className="Parrafo">
                        El sitio web de El Responsable está dirigido a la población mayor de 18 años por lo
                        que el origen de los datos recolectados en la misma, se presumirá que han sido
                        aportados por personas mayores de edad. De constatarse lo contrario, en
                        cumplimiento del artículo 7 de la Ley 1581 de 2012, se adoptarán las medidas
                        pertinentes para eliminar de inmediato dicha información, salvo que los datos
                        recogidos sean de naturaleza pública.
                    </p>
                    <p className="Parrafo1">
                        6.  POLÍTICAS DE SEGURIDAD
                    </p>
                    <p className="Parrafo">
                        El responsable, dando cumplimiento al artículo 4, literal “G” de la Ley Estatutaria
                        1581 de 2012 de Protección Datos, de implementan medidas técnicas, humanas y
                        administrativas para garantizar la seguridad de los registros otorgados por el titular
                        evitando su perdida, adulteración, consulta, uso o acceso no autorizado o
                        fraudulento.
                    </p>
                    <p className="Parrafo1">
                        7.DERECHOS DE LOS TITULARES DE LA INFORMACIÓN
                    </p>
                    <p className="Parrafo">
                        El titular de los datos personales podrá, en virtud de sus derechos:
                    </p>
                    <ul className="ListaParagrafoTres">
                        <li>
                            Conocer, actualizar y rectificar los datos personales que se hayan
                            recolectado bajo su autorización.
                        </li>
                        <li>
                            Solicitar la prueba de la autorización otorgada para el tratamiento de sus
                            datos.
                        </li>
                        <li>
                            Ser informado por el responsable del tratamiento, información sobre el uso
                            que le ha dado a sus datos personales.
                        </li>
                        <li>
                            Revocar su consentimiento para el tratamiento de datos personales en
                            cualquier momento, siempre y cuando, no lo impida una disposición legal o
                            contractual. La revocatoria de la autorización podrá hacerse de manera total
                            o parcial de acuerdo a las finalidades.
                        </li>
                        <li>
                            Solicitar la supresión de los datos personales en cualquier momento y
                            circunstancia, salvo que: a) el titular del dato tenga un deber legal o
                            contractual de permanecer en las bases de datos. b) la eliminación de los
                            datos obstaculiza actuaciones judiciales o administrativas. c) los datos sean
                            requeridos para proteger intereses jurídicamente tutelados del titular o
                            cuando sean necesarios para cumplir con una obligación legalmente
                            adquirida.
                        </li>
                    </ul>
                    <p className="Parrafo1">
                        8.CARÁCTER FACULTATIVO DE LAS RESPUESTAS
                    </p>
                    <p className="Parrafo">
                        De conformidad con la Ley 1581 de 2012, cuando las preguntas realizadas versen
                        sobre datos sensibles o de menores de edad, su respuesta será opcional.
                    </p>
                    <p className="Parrafo1">
                        9.PETICIONES, CONSULTAS Y RECLAMOS FRENTE AL TRATAMIENTO
                        DE DATOS
                    </p>
                    <p className="Parrafo">
                        Los titulares o sus causahabientes podrán consultar, rectificar, actualizar o suprimir
                        la información que repose en la base de datos de El Responsable a través de los
                        siguientes canales:
                    </p>
                    <ul className="ListaParagrafoTres">
                        <li>
                            Correo electrónico dirigido a: Hola@franca.agency

                        </li>
                        <li>
                            Número telefónico: +57 312 4663076
                        </li>
                    </ul>
                    <p className="Parrafo">
                        La solicitud deberá contener el nombre completo del titular de los datos
                        personales, el medio para recibir la respuesta y los motivos y/o hechos que dan
                        lugar a la solicitud.
                        En caso de quien actúe sea representante o causahabiente del titular, deberá
                        acreditar dicha calidad.
                    </p>
                    <p className="Parrafo1">
                        10.TÉRMINO DE RESPUESTA
                    </p>
                    <p className="Parrafo">
                        Las consultas y reclamos serán atendidos en los siguientes términos de
                        conformidad con la Ley 1581 de 2012:
                    </p>
                    <ul className="ListaParagrafoTres">
                        <li>
                            Consultas: Diez (10) días hábiles contados a partir de la fecha de
                            presentación.
                        </li>
                        <li>
                            Reclamos: Quince (15) días hábiles contados a partir del día siguientes del
                            recibo de la solicitud.
                        </li>
                    </ul>
                    <p className="Parrafo1">
                        11.SUJETOS QUE PUEDEN EJERCER LOS DERECHOS
                    </p>
                    <p className="Parrafo">
                        Los derechos de los titulares de la información personal podrán ser ejercidos por
                        las siguientes personas:
                    </p>
                    <ul className="ListaParagrafoTres">
                        <li>
                            El titular del dato personal.
                        </li>
                        <li>
                            Los causahabientes del titular de la información.
                        </li>
                        <li>
                            Por el representante y/o apoderado del titular.
                        </li>
                        <li>
                            En casos de menores de edad
                        </li>
                    </ul>
                    <p className="Parrafo1">
                        12.TRANSFERENCIA Y TRANSMISIÓN DE DATOS PERSONALES
                    </p>
                    <p className="Parrafo">
                        En desarrollo del objeto social de la Compañía, y la relación comercial con el titular
                        de los datos, en caso de que se requiera la transferencia o transmisión de los datos
                        recogidos a un tercero, El Responsable atenderá los requerimientos legales
                        aplicables de acuerdo a la autorización previa y a las finalidades informadas. Por lo
                        que, la información no podrá ser usada o destinada para un propósito diferente,
                        salvo que previamente exista un consentimiento expreso por parte del titular.
                    </p>
                    <p className="Parrafo1">
                        13. VIGENCIA
                    </p>
                    <p className="Parrafo">
                        Las bases de datos que albergan los registros, serán utilizadas por parte de El
                        Responsable por el tiempo que se requiera para la finalidad detallada en el
                        presente aviso de tratamiento de datos. Cuando se haya cumplido con la finalidad
                        para los cuales fueron recolectados los datos, sin perjuicio de lo que dispongan
                        otras normas legales, El Responsable realizará la supresión total de los datos, a
                        menos que exista alguna obligación legal o contractual de mantenerlos bajo
                        custodia.
                    </p>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}



