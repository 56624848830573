import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Col, Button, Row } from 'reactstrap';
import axios from "axios";

import './AllieForm.css';

export default class AllieMovForm extends Component {
   handleSubmit(e) {
      e.preventDefault();
      axios.post('/correo/', {
         name: e.target.name.value,
         email: e.target.email.value,
         message: e.target.Message.value
      }).then((response) => {
         debugger;
         if (response.data.status === 'succes') {
            alert("Message Sent.");
            //this.resetForm()
         } else if (response.data.status === 'fail') {
            alert("Message failed to send.")
         }
      })
   }
   render() {
      let closeModalAllie = this.props.closeModalAllie;
      return (
         <React.Fragment>
            <div className="AllieMovFormBox">
               <div className="row justify-content-center">
                  <div className="AlliesMovFormBox col-12">
                     <div className="MovFormBox">
                        <div className="logofranca">
                           <img src="/assets/img/logos/logoB.png" alt="franca" />
                        </div>
                        <div className="AllieCloseBTN" onClick={closeModalAllie}>
                           <img src="/assets/icons/CloseIcon.png" alt="" id="AllieCloseBTN" />
                           <p className="AllieCloseText">CLOSE</p>
                        </div>
                        <div className="col-11 offset-1 ">
                           <Form className="MovForm" onSubmit={(e) => this.handleSubmit(e)}>
                              <Label className="AlliesTitle AllieTitle">
                                 Estás a un paso de conocer tu nuevo
                              </Label>
                              <Label className="AlliesSubTitle Akzidenz-Roman">
                                 ALIADO ESTRATÉGICO
                              </Label>
                              <Col md={8}>
                                 <FormGroup>
                                    <Row>
                                       <Col xs={6}>
                                          <Input type="name" name="name" id="name"
                                             placeholder="Nombre"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }} />
                                       </Col>
                                       <Col xs={6}>
                                          <Input type="email" name="email" id="email" placeholder="E-mail"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }} />
                                       </Col>
                                    </Row>
                                 </FormGroup>
                              </Col>
                              <Col md={8}>
                                 <FormGroup>
                                    <Input type="textarea" name="Message" id="Message" rows="4" placeholder="¿En qué podemos ayudarte?"
                                       style={{
                                          backgroundColor: "#1D1D1B",
                                          color: "white"
                                       }} />
                                 </FormGroup>
                              </Col>
                              <Col md={12} style={{ textAling: "left" }}>
                                 <FormGroup>
                                    <Input className="form2" type="checkbox" name="Agree" value="Autorizo a la Política de Tratamiento de Datos Personales de FRANCA AGENCY" />
                                    <span className="Terminos_condiciones">AUTORIZO A LA POLITICA DE TRATAMIENTO DE DATOS PERSONALES DE FRANCA AGENCY.</span>
                                 </FormGroup>
                              </Col>
                              <Col md={8}>
                                 <FormGroup>
                                    <Button type="submit" className="AllieSubmitBtn">
                                       ENVIAR
                                    </Button>
                                 </FormGroup>
                              </Col>
                           </Form>
                        </div>
                        <div className="">
                           <img className="AllieMovLogo" src="/assets/img/Forms/AlliesForm.png" alt="logo" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}