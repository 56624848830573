import React, { Component } from 'react';

import './Inner.css'

/* import { data } from '../data';
import Modal from 'react-modal';
import BlogForm from '../../../Forms/blogForm'; */
import ContactUS from '../../../ContacUS/ContactUS';
import FamiliarFace from '../../../FamiliarFace/FamiliarFace';
import Blog1 from './Blogs/Blogs_1';

export default class Inner extends Component {
   constructor() {
      super();
      this.state = {
         showModalBlog: false,
      };
      this.handleOpenModalBlog = this.handleOpenModalBlog.bind(this);
      this.handleCloseModalBlog = this.handleCloseModalBlog.bind(this);
   }
   handleOpenModalBlog() {
      this.setState({ showModalBlog: true });
   }

   handleCloseModalBlog() {
      this.setState({ showModalBlog: false });
   }

   render() {
      /* let id = this.props.location.state.id; */
      return (
         <React.Fragment>
            <Blog1/>
            {/* <div id="top" className="InnerBanner">
               <img src={data[id].Banner} alt="" width="100%" />
               <p className="InnerTitle">
                  {data[id].Title}
               </p>
            </div>
            <div className="InnerTextBox">
               <div className="InnerBody">
                  <div className="InnerFirstP">
                     <img className="InnerFirstPic" src={data[id].Image1} alt="" />
                     <p className="InnerFirstText">
                        {data[id].Text1}

                     </p>
                  </div>
                  <div className="InnerSecondP">
                     <p className="InnerSecondText">
                        {data[id].Text2}
                     </p>
                     <img className="InnerSecondPic" src={data[id].Image2} alt="" />
                  </div>
                  { <div className="AuthorText">
                     <p className="verde">
                        Escrito por:
                     </p>
                     <p>
                        {data[id].Autor} - {data[id].Fecha}
                     </p>
                  </div> }
               </div>
               <button className="pptBTN" onClick={this.handleOpenModalBlog}>
                  LEER MÁS
               </button>
               <Modal
                  animation={false}
                  isOpen={this.state.showModalBlog}
                  contentLabel="onRequestClose Example"
                  onRequestClose={this.handleCloseModalBlog}
                  className="ModalBlog"
                  overlayClassName="OverlayBlog"
               >
                  <BlogForm closeModalBlog={this.handleCloseModalBlog} id={id} />
               </Modal>
            </div> */}
            <ContactUS />
            <FamiliarFace />
         </React.Fragment>
      );
      /*  */
   }
}