import React, { Component } from 'react';

import './News.css'
import Slider from './Slider';

export default class News extends Component {
   render() {
      /* let blogs = this.props.blogs; */
      let handleDelete = this.props.handleDelete
      return (
         <React.Fragment>
            {/* {blogs && <Slider blogs={blogs} handleDelete={handleDelete} />} */}
            <Slider handleDelete={handleDelete} />
         </React.Fragment>
      );
   }
}