import React, { Component } from 'react';

import './Blog.css';
import BlogMov from './BlogMov';
import BlogWeb from './BlogWeb';
import ContactUS from '../ContacUS/ContactUS';
import FamiliarFace from '../FamiliarFace/FamiliarFace';

export default class Blog extends Component {
   render() {
      return (
         <div className="BlogMainBox" id="Blog">
            <BlogWeb />
           <BlogMov />
            
         </div>
      );
   }
}