import React from 'react';
/* import { useHistory } from "react-router-dom"; */
import { Form, FormGroup, Input, Label, Col, Button } from 'reactstrap';
import axios from 'axios';

import './blogForm.css'

import { data } from '../Blog/News/data';

export default function BlogForm(props) {
    /* let history = useHistory(); */
    const handleSubmit = e => {
    const FileDownload = require('js-file-download');
        e.preventDefault();
        axios.post('/correo/blog/', {
            name: e.target.name.value,
            email: e.target.email.value,
        }).then((response) => {
            debugger;
            if (response.data.status === 'success') {
                alert("Message Sent.");
                e.target.name.value = " ";
                e.target.email.value = " ";
                /* history.push('http://www.test.franca.agency/assets/PDF/Blog1.pdf') */
            } else if (response.data.status === 'fail') {
                alert("Message failed to send.");
            }
        })
        /* document.location= "/assets/PDF/Blog1.pdf"; */
        //document.location=data[0].PDF ;
        
        FileDownload(data[0].PDF);
    }
    let closeModalBlog = props.closeModalBlog;
    /* let id = props.id; */
    return (
        <div className="blogFormMainBox">
            <div className="blogFormBox">
                <Form className="blogForm" onSubmit={handleSubmit}>
                    <Col md={6}>
                        <Label className="blogTitleForm Akzidenz-Roman" >
                            ¡YA ESTÁS <br/> A UN PASO!
                        </Label>
                        <p className="blogSubtitleForm WorkSans">
                            Llena este formulario para <br/> acceder al contenido.
                        </p>
                        <FormGroup>
                            <Label className="blogLabelForm">
                                Nombre
                            </Label>
                            <Input type="text" name="name" id="name" placeholder="Nombre" required
                                style={{
                                    backgroundColor: "#1D1D1B",
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="blogLabelForm">
                                Correo
                            </Label>
                            <Input type="email" name="email" id="email" placeholder="E-mail" required
                                style={{
                                    backgroundColor: "#1D1D1B",
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="BlogBTN">
                            <Button type="submit" className="Blogbtn" >
                                DESCARGAR <img src="/assets/icons/downloadBTN.png" alt=""/>
                            </Button>
                        </FormGroup>
                    </Col>
                </Form>
                <div className="closeBlogBTN" onClick={closeModalBlog}>
                    <img className="AllieCloseBTNPIC" src="/assets/icons/CloseIcon.png" alt=""
                        id="AllieCloseBTN" />
                    <p className="AllieCloseText">CLOSE</p>
                </div>
            </div>
        </div>
    );
}