import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Col, Button, Row, CustomInput } from 'reactstrap';
import axios from 'axios';

import './JoinForm.css';

export default class JoinMovForm extends Component {
   handleSubmit(e) {
      e.preventDefault();
      axios.post('/correo/Join', {
         name: e.target.name.value,
         email: e.target.email.value,
         position: e.target.select.value,
         file: e.target.customFile.value,
         message: e.target.Message.value
      }).then((response) => {
         debugger;
         if (response.data.status === 'succes') {
            alert("Message Sent.");
            //this.resetForm()
         } else if (response.data.status === 'fail') {
            alert("Message failed to send.")
         }
      })
   }
   render() {
      let closeModalJoin = this.props.closeModalJoin;
      return (
         <React.Fragment>
            <div className="JoinMovFormBox">
               <div className="row justify-content-center ">
                  <div className="MovJoinFormBox col-12">
                     <div className="row justify-content-center logofranca">
                        <img src="/assets/img/logos/logoB.png" alt="Franca" />
                     </div>
                     <div className="JoinCloseBTN" onClick={closeModalJoin}>
                        <img src="/assets/icons/CloseIcon.png" alt="" />
                        <p className="JoinCloseText">CLOSE</p>
                     </div>
                     <div className="">
                        <div className="col-11 offset-1">
                           <Form className="row">
                              <Label className="JoinsTitle JoinTitle">
                                 HAZ PARTE DE ESTE GRAN EQUIPO Y CONTEMOS
                              </Label>
                              <Label className="JoinsSubTitle Akzidenz-Roman">
                                 HISTORIAS JUNTOS
                              </Label>
                              <Col md={9}>
                                 <FormGroup>
                                    <Row>
                                       <Col xs={6}>
                                          <Input type="name" name="name" id="name" placeholder="Nombre"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }} />
                                       </Col>
                                       <Col xs={6}>
                                          <Input type="email" name="email" id="email" placeholder="E-mail"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }} />
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col xs={6}>
                                          <CustomInput type="select" name="select" id="select"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }}>
                                             <option value="">Cargo</option>
                                             <option>Ejecutiva/o de cuentas</option>
                                             <option>Director de Arte</option>
                                             <option>Director Digital</option>
                                             <option>Diseñador Gráfico</option>
                                             <option>Copy Creativo</option>
                                             <option>Estratega Digital / Community</option>
                                             <option>Desarrollador</option>
                                             <option>Animador</option>
                                             <option>Ejecutivo de Pauta</option>
                                             <option>Analista Digital</option>
                                          </CustomInput>
                                       </Col>
                                       <Col xs={6}>
                                          <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" label="Adjunta tu HV"
                                             style={{
                                                backgroundColor: "#1D1D1B",
                                                color: "white"
                                             }} />
                                       </Col>
                                    </Row>
                                 </FormGroup>
                              </Col>
                              <Col md={9}>
                                 <FormGroup>
                                    <Input type="textarea" name="Message" id="Message" rows="4" placeholder="Cuéntanos de ti"
                                       style={{
                                          backgroundColor: "#1D1D1B",
                                          color: "white"
                                       }} />
                                 </FormGroup>
                              </Col>
                              <Col md={9}>
                                 <FormGroup>
                                    <Button type="submit" className="JoinSubmitBtn">
                                       ENVIAR
                                    </Button>
                                 </FormGroup>
                              </Col>
                           </Form>
                        </div>
                        <div className="JoinMovLogo">
                           <img src="/assets/img/Forms/JoinForm.png" alt="logo" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}