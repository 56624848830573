export const data = [
   {
      Image1:"/assets/img/Blog/Blog1_1.png",
      Image2:"/assets/img/Blog/Blog1_2.png",
      Banner:"/assets/img/Blog/Banner_Blog1.png",
      Title:"EL MUNDO AVANZA",
      PreText:"La industria de la belleza y la cosmética dentro de la revolución digital",
      Text1:"El mundo avanza y la industria de la belleza y la cosmética no se quedan atrás. ",
      Text2:"Al ser una de las industrias más lucrativas y con más competencia del mundo, las marcas se han visto con la necesidad de mantenerse a la vanguardia y en constante cambio para responder a las necesidades de sus consumidores así como atraer nuevos clientes que cada vez más piden compañías responsables y con un impacto positivo en la sociedad.",
      PDF:"https://test.franca.agency/PDF/Franca_ppt_tendencias.pdf",
      Autor:"Carlos Posada",
      Fecha:"23 de marzo de 2021",
   },
]
