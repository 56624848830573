import React, { Component } from "react";

import './Agency.css';

export default class Agency extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="agencyMainBox">
                    <div className="agencyTitlebox">
                        <p id="agencyTitle" className="agencyTitle Akzidenz-Roman">
                            UNA AGENCIA
                        </p>
                        <img src="/assets/img/ScrollDown.png" alt="" />
                    </div>
                    <div className="agencyText Freight-light">
                        <p id="agencyFirsText" className="agencyFirsText">
                            que <span className="verde Freight-italic"><i>conecta</i></span>
                        </p>
                        <p id="agencySecondText" className="agencySecondText">
                            la <span className="verde Freight-italic"><i>esencia de su marca</i></span><br /> con su cliente.
                        </p>
                        <p id="agencyThirdText" className="agencyThirdText">Somos un equipo líder en la categoría<br /> <span className="verde Freight-italic"><i>Healthcare</i></span> y <span className="verde Freight-italic"><i>consumo masivo</i> </span> con <br />desarrollo estratégico pensado en este <br />sector.
                        </p>
                    </div>
                </div>
                <div className="">

                </div>
            </React.Fragment>
        );
    }
}