import React, { Component } from 'react';

import './Allies.css';

export default class AlliesMov extends Component{
   render(){
      return(
         <React.Fragment>
            <div className="container col-12 alliesMainMovBox">
               <div className="logoFranca row justify-content-center">
                  <img className="logoFrancaAllies" src="/assets/img/logos/logoW.png" alt="" />
               </div>
               <div className="alliesMovTitle">
                  <span className="Italic">Connecting people</span>
                  <p className="alliesMovSubTitle AkzidenText">WITH OUR </p>
                  <p className="alliesMovEndTitle AkzidenText">CLIENTS </p>
               </div>
               <div className="logosBlock justify-content-center col-12">
                  <div className="row justify-content-center">
                     <div className="col-6 AlliePicBoxNov" >
                        <img className="AlliePic" 
                        src="/assets/img/logos/NovoNordisk.png" alt="NOVO NORDISK"
                        />
                     </div>
                     <div className="col-6 AlliePicBoxMerz">
                        <img className="AlliePic" src="/assets/img/logos/MerzAesthetics.png" alt="MERZ AESTHETICS" style={{width:"100%"}} />
                     </div>
                  </div>
                  <div className="row justify-content-center">
                     <div className="col-6 AlliePicBoxFlips">
                        <img className="AlliePic" src="/assets/img/logos/Flips.png" alt="FLIPS" />
                     </div>
                     <div className="col-6 AlliePicBoxAbbvie">
                        
                     </div>
                  </div>
                  <div className="row justify-content-center">
                     <div className="col-6 AlliePicBoxGalderma">
                        <img className="AlliePic" src="/assets/img/logos/Galderma.png" alt="GALDERMA" style={{width:"100%"}} />
                     </div>
                     <div className="col-6 AlliePicBoxJaibel">
                        <img className="AlliePic" src="/assets/img/logos/Jaibel.png" alt="JAIBEL" />
                     </div>
                  </div>
                  <div className="row justify-content-center">
                     <div className="col-6 AlliePicBoxAllergan">
                        <img className="AlliePic" src="/assets/img/logos/Allergan.png" alt="Allergan" />
                     </div>                     
                     <div className="col-6 AlliePicBoxCoandes">
                        <img className="AlliePic" src="/assets/img/logos/Coandes.png" alt="COANDES" />
                     </div>
                  </div>
               </div>
            </div>
         </React.Fragment>
      );
   }
}
/* onMouseEnter={e => (console.log(e))}
   onMouseOver={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordisk.png)" 
         )}
   onMouseOut={e=> 
      (e.currentTarget.style.backgroundImage ="url(/assets/img/Background/NovoNordiskBlack.png)" )} 
*/