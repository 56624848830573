import React from 'react';
import { Row, Col, Form, FormGroup, FormText, Input, Label, Button } from 'reactstrap';
import axios from 'axios';

import './BlogAdmin.css'

function handleSubmit(e) {
    e.preventDefault();
    /* fetch('http://localhost:8000/news/', {
        method: 'POST',
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify(formData)
    }).then(()=> {
        console.log("new entry added")
    }) */
    axios.post('http://localhost:8000/news/', {
        Title: e.target.title.value,
        Autor: e.target.author.value,
        Text: e.target.textarea.value,
        Image1: "",
        Image2: "",
        Banner: "",
        PreText: "",
        PDF: "",
        Fecha: ""
    })
        .then((res) => {
            alert("Message Sent.");
            e.target.title.value = "";
            e.target.author.value = "";
            e.target.textarea.value = "";
            console.log(res)
        })
        .catch((err) => {
            console.log(err)
        })
}
export default function BlogAdmmin() {
    return (
        <div className="mainAdminBox">
            <h1>it works!</h1>
            <Form className="formBox" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Titulo del Blog
                            </Label>
                            <Input type="text" id="title" name="title" required />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Autor
                            </Label>
                            <Input type="text" id="author" name="author" required />
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                Foto Blog banner
                            </Label>
                            <Input type="file" />
                            <FormText color="muted">
                                solo se permiten archivos .jpg
                            </FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Fotos blog
                            </Label>
                            <Input type="file" multiple />
                            <FormText color="muted">
                                solo se permiten archivos .jpg
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Col md={6}>
                    <FormGroup>
                        <Label>
                            Texto Blog
                        </Label>
                        <Input type="textarea" id="textarea" name="textarea" required />
                    </FormGroup>
                </Col>
                <FormGroup>
                    <Button type="submit" className="sendAdminBTN">
                        Enviar
                    </Button>
                </FormGroup>
            </Form>
        </div>
    );
}